import React, { useEffect, useState, useRef } from 'react';
import { auth } from '../../firebase/config';
import { applyActionCode, onAuthStateChanged, sendEmailVerification } from "firebase/auth";
import { Box, Button, CircularProgress, Typography, Alert } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { navigate } from 'gatsby';

const ConfirmEmailPage: React.FC = () => {
    const [verified, setVerified] = useState(false);
    const [checking, setChecking] = useState(true);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const continueButtonRef = useRef<HTMLButtonElement>(null); // Reference to the continue button

    useEffect(() => {
        // Extract URL parameters and attempt verification if applicable
        const params = new URLSearchParams(window.location.search);
        const mode = params.get('mode');
        const oobCode = params.get('oobCode');

        if (oobCode) {
            applyActionCode(auth, oobCode)
                .then(() => {
                    setVerified(true);
                    setMessage('Your email has been successfully verified.');
                })
                .catch((error) => {
                    console.error("Error verifying email: ", error);
                    setError('Failed to verify email. The link may be expired or invalid.');
                })
                .finally(() => setChecking(false));
        } else {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    user.reload().then(() => {
                        if (user.emailVerified) {
                            setVerified(true);
                        }
                        setChecking(false);
                    });
                } else {
                    setChecking(false);
                    setError("User not signed in or session expired.");
                }
            });
        }
    }, []);

    useEffect(() => {
        // Automatically navigate to the dashboard after 5 seconds if the email is verified
        if (verified) {
            const timer = setTimeout(() => {
                continueButtonRef.current?.click();
            }, 5000); // 5 second delay

            return () => clearTimeout(timer); // Cleanup timeout
        }
    }, [verified]);

    const handleResendEmailVerification = () => {
        setLoading(true);
        const user = auth.currentUser;
        if (user && !user.emailVerified) {
            sendEmailVerification(user)
                .then(() => {
                    setMessage('Verification email resent. Check your mail...');
                    setLoading(false);
                    setTimeout(() => setMessage(''), 5000); // Reset message after 5 seconds
                })
                .catch((error) => {
                    console.error("Error resending verification email: ", error);
                    setError('Failed to resend verification email. Please try again later.');
                    setLoading(false);
                });
        } else {
            setLoading(false);
            setError(!user ? "User not found." : "Email already verified.");
        }
    };

    return (
        <Box sx={{ mt: 10, p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <Typography component="h1" variant="h1" sx={{ mt: 3, mb: 1, maxWidth: "100%" }}>
                Email Verification
            </Typography>
            <Typography component="h1" variant="body1" sx={{ mb: 4 }}>
                Check your email for a verification link to complete your account setup.
            </Typography>
            {checking ? (
                <CircularProgress />
            ) : verified ? (
                <>
                    <Typography component="h1" variant="h5">
                        Thank you for confirming your email!
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{ mt: 3 }}
                        onClick={() => navigate('/app/dashboard')}
                        ref={continueButtonRef} // Attach the ref to the button
                    >
                        Continue to ScreenKeep <ChevronRight />
                    </Button>
                </>
            ) : (
                <>
                    {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
                    {message && <Alert severity="success" sx={{ mb: 2 }}>{message}</Alert>}
                    {!verified && <Button
                        variant="contained"
                        sx={{ mt: 3 }}
                        onClick={handleResendEmailVerification}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Resend Verification Email'}
                    </Button>}
                </>
            )}
        </Box>
    );
};

export default ConfirmEmailPage;

export const Head: React.FC = () => <title>ScreenKeep - Confirm Email</title>;
